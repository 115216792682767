import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../catchmentMenu.module.scss';
import { MainContext } from '../../../../common/types/mainContext';
import { SwitchToggle } from '../../../../common/controls/toggle';
import { Button } from '../../../../common/controls/buttons';
import {
  CatchmentLocationsCountEnum,
  CatchmentMetricTypeEnum,
  CatchmentSocioDemoMetricEnum,
  CatchmentTypeEnum,
  ColorSchemeEnum,
  ReportItemType,
} from '../../../../common/reportItems/types';
import { usePopupPositions } from '../../../../common/reportItems/hooks';
import { useClickOutside, useURLParams } from '../../../../common/hooks';
import { ThresholdFilter } from '../../../../common/controls';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';
import { Catchment, ReportItemInfo } from '../../../../common/types/projectsHierarchy';
import { DefaultSwitcher } from '../../../../common/switchers';
import { SwitcherItem } from '../../../../common/switchers/default/interfaces';
import { getMinMax } from '../../../../common/utils/getMinMax';
import { SwitchModes } from '../../../../common/types/visualizationObjects';
import { usePreviousValue } from '../../../../common/hooks/usePrevious';

type TProps = {
  onClose: (value: boolean) => void;
  isMenuOpened: boolean;
};

const CatchmentMenu = ({ onClose, isMenuOpened }: TProps) => {
  const { t } = useTranslation();
  const {
    reportItemsDetails,
    updateReportItemsDetailsValue,
    primaryLocationId,
    selection: { selectedReport },
    catchmentLayer,
  } = useContext(MainContext);

  // console.log('TEST', reportItemsDetails);
  const previousValues = usePreviousValue({
    locationsCount: reportItemsDetails.catchmentLocationsCount,
    socioDemoMetric: reportItemsDetails.catchmentSocioDemoMetric,
    primaryLocationId,
    catchmentLayer,
  });

  const metricTypes: SwitcherItem[] = [
    { id: CatchmentMetricTypeEnum.SOURCE.toString(), value: t(`catchment.enum.SOURCE`) },
    { id: CatchmentMetricTypeEnum.DESTINATION.toString(), value: t(`catchment.enum.DESTINATION`) },
    { id: CatchmentMetricTypeEnum.SCORE.toString(), value: t(`catchment.enum.SCORE`) },
  ];

  const [colorScheme, setColorScheme] = useState<ColorSchemeEnum>(reportItemsDetails.colorScheme);
  const [catchmentType, setCatchmentType] = useState<CatchmentTypeEnum>(reportItemsDetails.catchmentType);
  const [catchmentDataOverlay, setCatchmentDataOverlay] = useState<boolean>(false);
  const [catchmentMetricType, setCatchmentMetricType] = useState<CatchmentMetricTypeEnum>(
    CatchmentMetricTypeEnum.DESTINATION,
  );
  const [threshold, setThreshold] = useState<{ min: number; max: number }>(reportItemsDetails.catchmentTreshold);
  // const [shouldUpdateThreshold, setShouldUpdateThreshold] = useState<boolean>(true);
  const { onChangeParams } = useURLParams();

  const reportItem = selectedReport?.report_items?.find(
    (i) => i.type === ReportItemType.CATCHMENT,
  ) as ReportItemInfo<Catchment>;

  const minMaxValue = useMemo(() => {
    /* ===== single location minmax calculation ===== */
    if (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE) {
      const data = reportItem?.data?.data?.find((location) => location.location_id === primaryLocationId)?.[
        catchmentType.toLowerCase() as 'home' | 'work'
      ];
      if (data) {
        // isochrones
        if (catchmentLayer === SwitchModes.ISOCHRONES) {
          if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION)
            return getMinMax(data, 'isochrone_share_of_target');
          if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE)
            return getMinMax(data, 'isochrone_catchment_score');
          return getMinMax(data, 'isochrone_share_of_source');
        }
        // catchment areas
        if (catchmentMetricType === CatchmentMetricTypeEnum.DESTINATION)
          return getMinMax(data, 'geo_entity_share_of_target');
        if (catchmentMetricType === CatchmentMetricTypeEnum.SCORE) return getMinMax(data, 'geo_entity_catchment_score');
        return getMinMax(data, 'geo_entity_share_of_source');
      }
    } else if (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE) {
      /* ===== multi location minmax calculation ===== */
      const data = reportItem?.visualization?.multiLocationPolygons?.[catchmentType.toLowerCase() as 'home' | 'work'];
      if (data) {
        // catchment areas
        if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS)
          return [data.inhabitantsMinRank, data.inhabitantsMaxRank];
        if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS)
          return [data.householdsMinRank, data.householdsMaxRank];
        if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME)
          return [data.averageIncomeMinRank, data.averageIncomeMaxRank];
        if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.RETAIL_SPENDING)
          return [data.purchasingPowerMinRank, data.purchasingPowerMaxRank];
      }
    }
    return undefined;
  }, [
    catchmentType,
    primaryLocationId,
    reportItem?.data?.data,
    catchmentMetricType,
    catchmentLayer,
    reportItem?.visualization?.multiLocationPolygons,
    reportItemsDetails.catchmentLocationsCount,
    reportItemsDetails.catchmentSocioDemoMetric,
  ]);

  const onChangeCatchmentMetricType = (item: SwitcherItem) => {
    if (item) {
      setCatchmentMetricType(item.id as CatchmentMetricTypeEnum);
    }
  };

  // useEffect(() => {
  //   // checking if threshold should be updated
  //   console.log(
  //     {
  //       catchmentLayer,
  //       reportItemsDetails,
  //       previousValues,
  //     },
  //     reportItemsDetails.catchmentLocationsCount !== previousValues?.locationsCount ||
  //       (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE &&
  //         reportItemsDetails.catchmentSocioDemoMetric !== previousValues.socioDemoMetric) ||
  //       (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
  //         primaryLocationId !== previousValues.primaryLocationId) ||
  //       catchmentLayer !== previousValues.catchmentLayer,
  //   );
  //   if (
  //     reportItemsDetails.catchmentLocationsCount !== previousValues?.locationsCount ||
  //     (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.MULTIPLE &&
  //       reportItemsDetails.catchmentSocioDemoMetric !== previousValues.socioDemoMetric) ||
  //     (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
  //       primaryLocationId !== previousValues.primaryLocationId) ||
  //     catchmentLayer !== previousValues.catchmentLayer
  //   ) {
  //     setShouldUpdateThreshold(true);
  //   }
  // }, [
  //   reportItemsDetails.catchmentLocationsCount,
  //   reportItemsDetails.catchmentSocioDemoMetric,
  //   primaryLocationId,
  //   catchmentLayer,
  // ]);

  useEffect(() => {
    if (minMaxValue) {
      if ((minMaxValue[0] || 0.001) !== threshold.min || (minMaxValue[1] || 1) !== threshold.max) {
        setThreshold({
          min: minMaxValue[0] || 0.001,
          max: minMaxValue[1] || 1,
        });
      }
      // if (shouldUpdateThreshold) {
      updateReportItemsDetailsValue('catchmentTreshold', {
        min: minMaxValue[0] || 0.001,
        max: minMaxValue[1] || 1,
      });
      onChangeParams('catchmentThresholdMin', minMaxValue[0] || 0.001);
      onChangeParams('catchmentThresholdMax', minMaxValue[1] || 1);
      // setShouldUpdateThreshold(false);
      // }
    }
  }, [minMaxValue]);

  useEffect(() => {
    if (reportItemsDetails.colorScheme !== colorScheme) {
      setColorScheme(reportItemsDetails.colorScheme);
    }
  }, [reportItemsDetails.colorScheme]);

  useEffect(() => {
    if (reportItemsDetails.catchmentDataOverlay !== catchmentDataOverlay) {
      setCatchmentDataOverlay(reportItemsDetails.catchmentDataOverlay);
    }
  }, [reportItemsDetails.catchmentDataOverlay]);

  const onSave = () => {
    if (reportItemsDetails.colorScheme !== colorScheme) {
      trackUserAction(
        'Catchment color scheme changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Color scheme: ${colorScheme}`,
      );
      updateReportItemsDetailsValue('colorScheme', colorScheme);
      onChangeParams('colorScheme', colorScheme);
    }
    if (reportItemsDetails.catchmentType !== catchmentType) {
      trackUserAction(
        'Catchment source reference changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Source reference: ${catchmentType}`,
      );
      updateReportItemsDetailsValue('catchmentType', catchmentType);
      onChangeParams('catchmentType', catchmentType);
    }
    if (reportItemsDetails.catchmentDataOverlay !== catchmentDataOverlay) {
      trackUserAction(
        'Catchment data overlay changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `New value: ${catchmentDataOverlay ? 'Yes' : 'No'}`,
      );
      updateReportItemsDetailsValue('catchmentDataOverlay', catchmentDataOverlay);
      onChangeParams('catchmentDataOverlay', catchmentDataOverlay ? 'YES' : 'NO');
    }
    if (reportItemsDetails.catchmentMetricType !== catchmentMetricType) {
      trackUserAction(
        'Catchment metric type changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `New metric: ${catchmentMetricType.toString()}`,
      );
      updateReportItemsDetailsValue('catchmentMetricType', catchmentMetricType);
      onChangeParams('catchmentMetricType', catchmentMetricType);
    }
    if (
      reportItemsDetails.catchmentTreshold.min !== threshold.min ||
      reportItemsDetails.catchmentTreshold.max !== threshold.max
    ) {
      trackUserAction(
        'Catchment threshold changed',
        UserActionsEnum.REPORT_ITEM_SETTING_CHANGED,
        `Min: ${threshold.min}, max: ${threshold.max}`,
      );
      updateReportItemsDetailsValue('catchmentTreshold', threshold);
      onChangeParams('catchmentThresholdMin', threshold.min);
      onChangeParams('catchmentThresholdMax', threshold.max);
    }
    onMenuClose();
  };

  const onMenuClose = () => {
    onClose(false);
  };

  const { ref } = usePopupPositions(`menu-${ReportItemType.CATCHMENT}`, onMenuClose, 145, 32, 190);
  useClickOutside(ref, onMenuClose, isMenuOpened);

  const thresholdFilterLabel = useMemo(() => {
    if (reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE) {
      return t('catchment.card.metricThreshold');
    } else {
      if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.INHABITANTS) {
        return t('catchment.card.inhabitantsThreshold');
      }
      if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLDS) {
        return t('catchment.card.householdsThreshold');
      }
      if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.HOUSEHOLD_INCOME) {
        return t('catchment.card.householdIncomeThreshold');
      }
      if (reportItemsDetails.catchmentSocioDemoMetric === CatchmentSocioDemoMetricEnum.RETAIL_SPENDING) {
        return t('catchment.card.retailSpendingThreshold');
      }
    }
    return t('catchment.card.metricThreshold');
  }, [
    reportItemsDetails.catchmentMetricType,
    reportItemsDetails.catchmentSocioDemoMetric,
    reportItemsDetails.catchmentLocationsCount,
    t,
  ]);

  return (
    <div className={`${styles.catchmentMenu} ${styles.bigger}`} ref={ref}>
      {isMenuOpened && (
        <div>
          <div className={styles.header}>
            <h3 className={styles.title}>{t('reportItem.settings')}</h3>
          </div>
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.sourceReference')}</div>
            <div className={styles.content}>
              <SwitchToggle
                leftLabel={t('catchment.card.home')}
                leftValue={CatchmentTypeEnum.HOME}
                rightLabel={t('catchment.card.work')}
                rightValue={CatchmentTypeEnum.WORK}
                selectedValue={catchmentType}
                onClick={(value) => setCatchmentType(value as CatchmentTypeEnum)}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.mapPalette')}</div>
            <div className={styles.content}>
              <SwitchToggle
                leftLabel={t('catchment.card.color')}
                leftValue={ColorSchemeEnum.COLORFUL}
                rightLabel={t('catchment.card.monochrome')}
                rightValue={ColorSchemeEnum.MONOCHROME}
                selectedValue={colorScheme}
                onClick={(value) => setColorScheme(value as ColorSchemeEnum)}
              />
            </div>
          </div>
          <hr />
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.showDataOverlay')}</div>
            <div className={styles.content}>
              <SwitchToggle
                leftLabel={t('reportItem.yes')}
                leftValue="YES"
                rightLabel={t('reportItem.no')}
                rightValue="NO"
                selectedValue={catchmentDataOverlay ? 'YES' : 'NO'}
                onClick={(value) => setCatchmentDataOverlay(value === 'YES')}
              />
            </div>
          </div>
          <hr />
          <div className={styles.row}>
            <div className={styles.rowHeader}>{t('catchment.card.metricType')}</div>
            <div className={styles.content}>
              <DefaultSwitcher
                items={metricTypes}
                activeItem={{
                  id: catchmentMetricType.toString(),
                  value: t(`catchment.enum.${catchmentMetricType}`),
                }}
                onChange={onChangeCatchmentMetricType}
              />
            </div>
          </div>
          <hr />
          <div className={styles.row}>
            <div className={styles.rowHeader}>{thresholdFilterLabel}</div>
            <div className={styles.content}>
              <ThresholdFilter
                initialValue={{ min: threshold.min, max: Math.ceil(threshold.max * 10000) / 10000 }}
                minValue={minMaxValue ? minMaxValue[0] : 0}
                maxValue={minMaxValue ? Math.ceil(minMaxValue[1] * 10000) / 10000 : 1}
                onChange={(event) => {
                  if (event.min === event.max && event.max >= (minMaxValue ? minMaxValue[0] : 0) + 0.0001) {
                    event.min = event.min - 0.0001;
                  }
                  if (event.min === event.max && event.max === (minMaxValue ? minMaxValue[0] : 0)) {
                    event.max = event.max + 0.0001;
                  }
                  setThreshold({ min: event.min, max: event.max });
                }}
                percentage={reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE}
                unitSymbol={
                  reportItemsDetails.catchmentLocationsCount === CatchmentLocationsCountEnum.SINGLE &&
                  catchmentMetricType !== CatchmentMetricTypeEnum.SCORE
                    ? '%'
                    : ''
                }
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button onClick={() => onClose(false)}>{t('reportItem.cancel')}</Button>
            <Button layout="primary" onClick={onSave} animation>
              {t('reportItem.save')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CatchmentMenu;
