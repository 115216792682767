import React, { useContext, useState, useEffect, useMemo, ChangeEvent } from 'react';
import styles from '../locations.module.scss';
import { MainContext } from '../../../../common/types/mainContext';
import LocationRow from './locationRow';
import { Button } from '../../../../common/controls/buttons';
import { maxLengthOfString } from '../../../../common/utils/stringUtils';
import { addImageUrlToLocations } from '../../../../common/locations/utils';
import { trackUserAction, UserActionsEnum } from '../../../../../mixpanel';
import { useURLParams } from '../../../../common/hooks';
import { Checkbox } from '../../../../common/controls/checkbox';
import { Field, InputTypesEnum } from '../../../../common/inputs';
import { CrossIcon } from '../../../../common/icons/controls';
import { LocationInfo } from '../../../../common/types/projectsHierarchy';

interface SimpleLocationItem extends Pick<LocationInfo, 'id' | 'name'> {
  selected: boolean;
}

type LocationItemRecord = Record<string, boolean>;
type TProps = {
  onClose: () => void;
};

const LocationsList = ({ onClose }: TProps) => {
  const { selection, selectedLocations, setSelectedLocations, primaryLocationId, setPrimaryLocationId } =
    useContext(MainContext);
  const { selectedProject, selectedReport } = selection;
  const [preselected, setPreselected] = useState<SimpleLocationItem[]>([]);
  const { onChangeParams } = useURLParams();
  const [searchString, setSearchString] = useState('');

  const reportLocations = useMemo(
    () =>
      selectedProject?.locations.filter((location) => selectedReport?.location_ids?.some((id) => id === location.id)) ??
      [],
    [],
  );

  const { filteredLocations, filteredLocationsRecord } = useMemo(() => {
    if (!searchString)
      return {
        filteredLocations: preselected,
        filteredLocationsRecord: preselected.reduce<LocationItemRecord>(
          (acc, current) => ({ ...acc, [current.id]: true }),
          {},
        ),
      };

    const filteredLocations = [];
    const filteredLocationsRecord: LocationItemRecord = {};

    for (let i = 0; i < preselected.length; i++) {
      const location = preselected[i];

      if (location.name.toLowerCase().includes(searchString.toLowerCase())) {
        filteredLocations.push(location);
        filteredLocationsRecord[location.id] = true;
      }
    }

    return {
      filteredLocations,
      filteredLocationsRecord,
    };
  }, [preselected, searchString]);

  const isAllFilteredLocationSelected = useMemo(() => {
    return filteredLocations.every((location) => location.selected);
  }, [filteredLocations]);

  const onPreselectionChange = (id: string) => {
    setPreselected((prevState) =>
      prevState.map((item) => {
        return item.id === id ? { ...item, selected: !item.selected } : item;
      }),
    );
  };

  const onSelectAllClick = () => {
    setPreselected((prevState) =>
      prevState.map((item) => ({
        ...item,
        selected: filteredLocationsRecord[item.id] ? !isAllFilteredLocationSelected : item.selected,
      })),
    );
  };

  const onSave = () => {
    const newSelected =
      reportLocations?.filter((reportLocation) =>
        preselected.some((item) => item.selected && item.id === reportLocation.id),
      ) || [];

    const selectedLocationsIds = newSelected.map(({ id }) => id).join(' ');
    const locationWithImage = addImageUrlToLocations(newSelected);
    setSelectedLocations(locationWithImage);
    onChangeParams('selectedLocations', selectedLocationsIds);

    if (!newSelected.some((location) => location.id === primaryLocationId)) {
      const locationId = newSelected[0].id;
      setPrimaryLocationId(locationId);
      trackUserAction(
        'Primary location has been changed',
        UserActionsEnum.PRIMARY_LOCATION_CHANGE,
        `Default primary location set after changing the list of selected locations. Location ID: ${locationId}`,
      );
      onChangeParams('primaryLocation', locationId);
    }
    onClose();
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    trackUserAction('Location search field changed', UserActionsEnum.SEARCH_PROJECTS_REPORTS, value);
    setSearchString(value);
  };

  useEffect(() => {
    const selectionData: SimpleLocationItem[] =
      reportLocations?.map((location) => ({
        id: location.id,
        name: location.name,
        selected: selectedLocations.some((selectedLocation) => selectedLocation.id === location.id),
      })) || [];
    setPreselected(selectionData);
  }, []);

  return (
    <div className={styles.locationsList}>
      <div className={styles.title}>{`Search ${maxLengthOfString(selectedReport?.name || '', 35)}`}</div>
      <div className={styles.searchInput}>
        <Field
          id="locationsSearch"
          name="locationsSearch"
          onChange={onSearch}
          value={searchString}
          placeholder="Search locations"
          fieldType={InputTypesEnum.WITH_ICON}
          icon={searchString && <CrossIcon />}
          onIconClick={() => setSearchString('')}
          iconSide="right"
        />
      </div>
      <div className={`${styles.reportLocations} custom-scrollbar`}>
        <div className={styles.groupTitle}>
          <span>Locations on view</span>
          <Checkbox
            isChecked={isAllFilteredLocationSelected}
            disabled={!reportLocations || reportLocations.length === 0}
            onChange={onSelectAllClick}
            name="select-all-locations"
          />
        </div>
        {filteredLocations.map((location) => (
          <LocationRow
            id={location.id}
            name={location.name}
            selected={location.selected}
            onClick={onPreselectionChange}
          />
        ))}
      </div>
      <div className={styles.buttons}>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          layout="primary"
          onClick={onSave}
          disabled={preselected.filter((item) => item.selected).length === 0}
          animation
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default LocationsList;
